import Swiper from 'swiper/bundle';

export default class Slider {
  producersSlider = undefined;

  featuredSlider = undefined;

  mobileProductSwiper = undefined;

  breakpoint = window.matchMedia('(min-width:62em)');

  enableMobileSwiper() {
    this.producersSlider = new Swiper('.producteurs-slider', {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-btn-next',
        prevEl: '.swiper-btn-prev',
      },
    });
  }

  enableSwiper() {
    this.featuredSlider = new Swiper('.featured-swiper', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-btn-next-featured',
        prevEl: '.swiper-btn-prev-featured',
      },
    });
  }

  enableMobileProductSwiper() {
    this.mobileProductSwiper = new Swiper('.mobile-product-swiper', {
      slidesPerView: 'auto',
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-btn-next-featured',
        prevEl: '.swiper-btn-prev-featured',
      },
    });
  }

  breakpointChecker() {
    // if larger viewport and multi-row layout needed
    if (this.breakpoint.matches) {
      // clean up old instances and inline styles when available
      if (this.producersSlider !== undefined) {
        this.producersSlider.destroy(true, true);
      }

      if (this.featuredSlider !== undefined) {
        this.featuredSlider.destroy(true, true);
      }

      if (this.mobileProductSwiper !== undefined) {
        this.mobileProductSwiper.destroy(true, true);
      }

      // else if a small viewport and single column layout needed
    } else {
      // fire small viewport version of swiper
      this.enableMobileSwiper();
      this.enableMobileProductSwiper();
    }
  }

  init() {
    // keep an eye on viewport size changes
    this.breakpoint.addEventListener('change', this.breakpointChecker);

    // kickstart
    this.breakpointChecker();
    this.enableSwiper();
  }
}
