$(() => {
  $(document).on('click', '.navbar-search-icon', () => {
    $('.navbar-search-input').focus();
  });
});

$(window).on('resize', () => {
  const searchInputOffset = $('.navbar-search-icon').offset();
  $('.ui-autocomplete').css('left', searchInputOffset.left);
}).trigger('resize');
