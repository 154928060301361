import prestashop from 'prestashop';
import $ from 'jquery';
import Offcanvas from 'bootstrap/js/dist/offcanvas';

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = (html) => {
  const respBlockCartBody = $('.offcanvas-body', html);
  const respBlockCartFooter = $('.offcanvas-footer', html);
  const respBlockCartNumber = $('.right-offcanvas-title .number', html);

  const offcanvasBlockCart = $('#offcanvasBlockCart');

  if (!offcanvasBlockCart.length) {
    $('.nav-right-column').append(html);
  } else {
    $('.offcanvas-body', offcanvasBlockCart).empty().append(respBlockCartBody.html());
    $('.offcanvas-footer', offcanvasBlockCart).empty().append(respBlockCartFooter.html());
    $('.right-offcanvas-title .number', offcanvasBlockCart).empty().append(respBlockCartNumber.html());
  }

  if (!offcanvasBlockCart.hasClass('show') && $('#product').length) {
    const bsOffcanvas = new Offcanvas(offcanvasBlockCart[0]);
    bsOffcanvas.show();
  }
};

function checkProductInCart() {
  $.ajax({
    type: 'GET',
    url: '/index.php?fc=module&module=acquapazza_core&controller=check',
    dataType: 'json',
    success(response) {
      if (response.productInCart) {
        $('.section-gift-left').hide();
      } else {
        $('.section-gift-left').show();
      }
    },
  });
}

function checkOrderableCart() {
  return $.ajax({
    type: 'POST',
    url: window.prestashop.urls.pages.order,
    data: {
      ajax: 1,
      action: 'checkCartStillOrderable',
    },
    dataType: 'json',
  });
}

$(document).ready(() => {
  prestashop.on('updatedCart', () => {
    checkProductInCart();
  });

  const saveGiftMessage = (callback) => {
    const giftMessage = $('#delivery_message').val();

    $.ajax({
      type: 'POST',
      url: '/index.php?fc=module&module=acquapazza_core&controller=cart',
      data: { gift_message: giftMessage },
      dataType: 'json',
      success() {
        if (typeof callback === 'function') {
          callback();
        }
      },
    });
  };

  $(document).on('blur', '#delivery_message', () => {
    saveGiftMessage();
  });

  $(document).on('click', '#cart-btn-order', (event) => {
    event.preventDefault();

    checkOrderableCart().then((resp) => {
      if (resp.errors) {
        const cartNotOrderableMessage = $('#cartNotOrderableMessage');
        if (cartNotOrderableMessage) {
          cartNotOrderableMessage.show();
        }
        // hide #cartNotOrderableMessage after 5 seconds
        setTimeout(() => {
          $('#cartNotOrderableMessage').hide();
        }, 5000);
      } else {
        const href = $('#cart-btn-order').attr('href');
        saveGiftMessage(() => {
          window.location.href = href;
        });
      }
    });
  });
});
