const scrollspy = document.querySelector('.scrollspy');

if (scrollspy) {
  document.querySelector('.scrollspy').addEventListener('click', (e) => {
    e.preventDefault();
    const targetElement = document.querySelector('#scrollspyinfo');
    const headerHeight = document.querySelector('#header').offsetHeight;
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    }
  });
}

// Menu scroll autohide
let lastKnownScrollPosition = 0;
let ticking = false;

const header = $('.nav-desktop');

function onScroll(scrollPosition) {
  if (scrollPosition >= 2) {
    header.addClass('scrolling-down');
  } else {
    header.removeClass('scrolling-down');
  }
}

document.addEventListener('scroll', () => {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      onScroll(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});
