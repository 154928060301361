import 'bootstrap/js/dist/offcanvas';

$(document).ready(() => {
  const menuHtml = $('#adtm_menu').html();

  $(document).on('click', '.adtm-mobile #adtm_menu .sub .a-niveau1', function (e) {
    e.preventDefault();
    const submenuMobile = $(this).siblings('.adtm-sub');

    if (submenuMobile) {
      $('#adtm_menu').html(submenuMobile.html());
      $('#mobile-back').removeClass('d-none');
      $('#close-menu-button').addClass('d-none');
      $('.navbar-atdm-elements').addClass('d-none');
      $('.adtm-line-footer, .actionnav-menu').addClass('d-none');
    }
  });

  $(document).on('click', '#mobile-back', (e) => {
    e.preventDefault();
    $('#adtm_menu').html(menuHtml);
    $('#mobile-back').addClass('d-none');
    $('#close-menu-button').removeClass('d-none');
    $('.navbar-atdm-elements').remove('d-none');
    $('.adtm-line-footer, .actionnav-menu').removeClass('d-none');
  });
});
