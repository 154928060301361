$(() => {
  const configBtn = $('#acb-config');
  const configBackBtn = $('#back-config');
  const cookieDefault = $('#acb-content-default');

  configBtn.on('click', () => {
    cookieDefault.toggleClass('hide');
  });

  configBackBtn.on('click', () => {
    cookieDefault.toggleClass('hide');
  });
});
