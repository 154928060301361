import $ from 'jquery';

const $scrollingText = $('.scrolling-text');
const $scrollingTextContainer = $('.scrolling-text-container');

function checkTextWidth() {
  if ($scrollingText.width() > $scrollingTextContainer.width()) {
    $scrollingText.css('animation-play-state', 'running');
  } else {
    $scrollingText.css('animation-play-state', 'paused');
  }
}

$(window).on('load', checkTextWidth);
$(window).on('resize', checkTextWidth);
